body {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  color: white;
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
}

.header {
  justify-content: center;
  align-items: center;
  display: flex;
}

.todo {
  display: flex;
  margin: 0.5rem;
  background: #fff;
  font-size: 1.5rem;
  color: #000;
  align-items: center;
  transition: all 1s ease;
}

.todo li {
  flex: 1;
  overflow: auto;
}

.todo-item {
  padding: 0rem 0.5rem;
}

.todo-input {
  display: flex;
  justify-content: center;
  align-items: center;
}
input.todo-input-field {
  width: 60%;
}

button.todo-button {
  width: 3rem;
}

.todo-input-field,
.todo-button {
  border: none;
  background: white;
  font-size: 2rem;
}
.todo-button {
  color: #ff6f47;
  background: #f7fffe;
  cursor: pointer;
  transition: all 0.3s ease;
}
.todo-button:hover {
  background: #ff6f47;
  color: white;
}


.filter {
  display: flex;
    justify-content: center;
    align-items: center;
}

.todo-container {
  display: flex;
  justify-content: center;
}

.todo-list {
  min-width: 30%;
  list-style: none;
  width: 80%;
  padding-inline-start: 0px;
}

.trash-btn, .complete-btn {
  background: #ff6f47;
  color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
}

.complete-btn {
  background: #0bd4a2;
}

.completed {
  text-decoration: line-through;
  opacity: 0.5;
}

.fa-trash, .fa-check {
  pointer-events: none;
}

/* For mobile phones: */

[class*="col-"] {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .col-s-1 {
    width: 8.33%;
  }
  .col-s-2 {
    width: 16.66%;
  }
  .col-s-3 {
    width: 25%;
  }
  .col-s-4 {
    width: 33.33%;
  }
  .col-s-5 {
    width: 41.66%;
  }
  .col-s-6 {
    width: 50%;
  }
  .col-s-7 {
    width: 58.33%;
  }
  .col-s-8 {
    width: 66.66%;
  }
  .col-s-9 {
    width: 75%;
  }
  .col-s-10 {
    width: 83.33%;
  }
  .col-s-11 {
    width: 91.66%;
  }
  .col-s-12 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }
}